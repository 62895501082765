.my-designs-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 40px;
}

.my-designs-title-flex {
  display: flex;
  flex-direction: column;
}

.my-designs-title {
  margin-top: 30px;
  margin-bottom: 45px;
  display: inline-block;
}



.product-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 30vh;
    /* min-height: 50%; */
    object-fit: cover;
  }

  >div {
    padding: 20px;

    h3 {
      font-size: 20px;
      margin: 0px;
    }

    .button {
      padding: 8px 20px;
      font-size: 16px
    }
  }
}

@media screen and (max-width:320px) {
  .my-designs-title-flex {
    gap: 18px;
  }

  // .product-card {
  //   margin-left: 50px;
  //   width: 68%;

  // }
  .product-card img {
    width: auto;
    height: 30vh;
    object-fit: contain;
  }

  .product-card>div .button {
    font-size: 10px;
  }

  .product-card>div h3 {
    font-size: 15px;
  }

  .mt-2 {
    font-size: 14px;
  }
}

@media screen and (min-width:330px) and (max-width:420px) {
  .my-designs-title-flex {
    gap: 18px;
  }

  // .product-card {
  //   margin-left: 62px;
  //   width: 68%;

  // }

  .product-card img {
    width: auto;
    height: 30vh;
    object-fit: contain;
  }

  .product-card>div .button {
    font-size: 10px;
  }

  .product-card>div h3 {
    font-size: 15px;
  }

  .mt-2 {
    font-size: 14px;
  }
}

@media screen and (min-width:425px) and (max-width:540px) {
  .my-designs-title-flex {
    gap: 18px;
  }

  .product-card {
    // margin-left: 85px;
    width: 100%;

  }

  .product-card img {
    width: auto;
    height: 30vh;
    object-fit: contain;
  }

  .product-card>div .button {
    font-size: 10px;
  }

  .product-card>div h3 {
    font-size: 15px;
  }

  .mt-2 {
    font-size: 14px;
  }
}


@media (max-width:1080px) {
  .my-designs-cards {
    grid-template-columns: repeat(2, 1fr);
  }

}

@media (max-width:620px) {
  .my-designs-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}