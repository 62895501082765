.your-design-hero {
  margin-bottom: 90px;
  display: flex;
  gap: 30px;
}

.yd-select {
  width: 24px;
  height: 24px;
  background-color: #000000;
  border-radius: 50%;
  border: 5px solid #ebe3e7
}

.selection-drawer {
  height: 0px;
  transition: height 1.1s ease-out 1.1s;
}

.selection-drawer.active {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;
  background-color: var(--white);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 10px 10px 0px 10px;




  .selection-badge {
    background-color: #ffffff;
    padding: 6px 8px;
    width: fit-content;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid #0882df;
    border-left: 3px solid #0882df;
    margin-left: 10px;
    color: #0882df;
    font-size: 11px;
    font-weight: 500;
    margin-top: 10px;
    // margin-bottom: 10px;
  }
}

.yd-select-active {
  width: 24px;
  height: 24px;
  background-color: #000000;
  border-radius: 50%;
  border: 2px solid #ebe3e7
}

.your-design-img-wrap {
  position: relative;
  width: 1000px;
  height: 600px;
  border-radius: 12px;
  overflow: hidden;

  .your-design-img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .yd-btn {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--black);
    border: 8px solid #EBE7E3;
  }

  .yd-btn-active {
    border: 6px solid #EBE7E3;
    background-image: radial-gradient(circle closest-side, #000 60%, #fff 40%);
  }



  .yd-btn1 {
    top: 27%;
    left: 30%;
  }

  .yd-btn2 {
    bottom: 30%;
    left: 5%;
  }

  .yd-btn3 {
    bottom: 40%;
    left: 25%;
  }

  .yd-btn4 {
    bottom: 10%;
    left: 25%;
  }

  .yd-btn5 {
    top: 30%;
    left: 47%;
  }

  .yd-btn6 {
    bottom: 37%;
    left: 48%;
  }

  .yd-btn7 {
    bottom: 20%;
    left: 50%;
  }

  .yd-btn8 {
    bottom: 13%;
    left: 50%;
  }

  .yd-btn9 {
    top: 42%;
    right: 13%;
  }

  .yd-btn10 {
    bottom: 12%;
    right: 5%;
  }

  .yd-btn11 {
    bottom: 5%;
    right: 25%;
  }


  .your-design-img-icons {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: var(--white);
    border-radius: 12px;
    padding: 4px;

    button {
      background: transparent;
      border: none;
      font-size: 22px;
      margin: 0px 10px;
    }
  }
}



.your-design-options-parent {
  width: 15%;
  height: 600px;
  overflow: auto;
  padding: 0px 10px;

}

.your-design-img-options {
  .unactive {
    background-color: var(--secondary);
    border-radius: 12px;
    margin-bottom: 10px;

    .design-img-option {
      // height: 150px !important;
      margin: 10px 0px;
    }
  }

  .active-option {
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .design-img-option {
      // height: 150px !important;
    }
  }

  .active-option>div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;
    flex-wrap: wrap;

    .details {
      text-decoration: underline;
    }

    .swap {
      color: var(--primary);

      svg {
        margin-right: 2px;
      }
    }
  }
}

.design-img-option {
  width: 100%;
  // height: 160px;
  margin-bottom: 10px;
  border-radius: 12px;
  cursor: pointer;
}

.your-design-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.your-design-items {
  margin-bottom: 90px;
}

.cards-wrap {
  margin-bottom: 45px;
}

.your-design-card {
  background-color: var(--white);
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  color: var(--black);
  height: 100%;

  img {
    width: 100%;
    height: 380px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
  }

  .price {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-left: 6px;
  }
}

.options-responsive-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 6px 10px;
  background-color: var(--white);
  border: none;
  border-radius: 10px;
  display: none;
}

.your-design-options-mobile {
  display: none;
}

// Responsive

@media (max-width: 960px) {
  .your-design-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  .your-design-card {
    img {
      // height: 160px;
    }

    h3 {
      font-size: 14px;

    }

    .price {
      font-size: 18px;
    }
  }

  .yd-select {
    width: 18px;
    height: 18px;
    background-color: #000000;
    border-radius: 50%;
    border: 5px solid #ebe3e7
  }

  .yd-select-active {
    width: 18px;
    height: 18px;
    background-color: #000000;
    border-radius: 50%;
    border: 2px solid #ebe3e7
  }
}

@media (max-width: 1180px) {
  .your-design-img-wrap {
    width: 100vw;
    height: calc(60vw - 120px);
  }

  .your-design-options-parent {
    width: 25%;
  }

  .your-design-hero {
    gap: 10px;
  }

  .your-design-img-options .active-option>div {
    justify-content: center;
  }
}

@media (max-width: 1400px) {
  .your-design-options-parent {
    display: none;
  }

  .options-responsive-btn {
    display: block;
  }

  .your-design-options-mobile {
    display: block;
    position: fixed;
    background: var(--white);
    width: 250px;
    height: 100% !important;
    padding: 20px;
    border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
    top: 80px;
    left: 0;
    z-index: 9999;
    overflow: scroll;
  }
}

@media (max-width: 768px) {
  .your-design-img-wrap {
    width: 100vw !important;
    height: 60vw !important;
  }

  .your-design-options-mobile {
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    background: var(--white);
    width: 100%;
    height: 40% !important;
    padding: 20px;
    border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
    border-top-left-radius: 12px;
    // transform: translate(-50%, 0);
    transition: "height 0.1s ease-out 0.1s";
    bottom: 0px;
    top: auto;
    left: 0;
    z-index: 9999;
    overflow: scroll;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  }
}

@media (max-width: 560px) {
  .your-design-hero {
    margin-bottom: 30px;
  }

  .your-design-img-wrap {
    width: 100vw;
    height: 60vw;

    .yd-btn {
      width: 14px !important;
      height: 14px !important;
      border: 1px solid #EBE7E3 !important;
      // border-radius: 10px;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .yd-btn {
      width: 14px !important;
      height: 14px !important;
      border: 1px solid #EBE7E3 !important;
      // border-radius: 10px;
    }
  }

  .your-design-options-mobile {
    display: block;
    position: fixed;
    background: var(--white);
    width: 100%;
    height: 40% !important;
    padding: 20px;
    border-top-right-radius: 12px;
    // border-bottom-right-radius: 12px;
    bottom: 0px;
    top: auto;
    left: 0;
    // z-index: 9999999999;
    overflow: scroll;
  }

  .your-design-img-wrap .your-design-img-icons {
    button {
      font-size: 18px;
      margin: 0px 6px;
    }
  }
}