.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 11111111111111;
  text-align: left;

  .login-box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    // height: auto;
    // max-height: 75vh;
    margin-top: calc(100vh - 85vh - 50px);
    background: var(--white);
    border-radius: 10px;
    padding: 60px;
    overflow: auto;

    button {
      margin-bottom: 0px;
      width: 100%;
      padding: 16px 0px;
    }
  }

  .login-info {
    text-align: center;
    margin-bottom: 30px;

    img {
      width: 110px;
      height: 110px;
    }

    svg {
      float: right;
    }

    h3 {
      font-weight: 600;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    p {
      color: var(--muted);
    }
  }

  .otp-box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 75vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--white);
    border-radius: 10px;
    padding: 45px 60px;
    overflow: auto;

    button {
      margin-bottom: 0px;
      width: 100%;
      padding: 16px 0px;
    }
  }

  .otp-inputs {
    padding: 6px 45px;

    .inputs {
      justify-content: center;
      gap: 30px;
    }

    p {
      margin-top: 12px;
      text-align: end;
      color: var(--muted);
    }
  }

  .register-box {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    // max-height: 80vh;
    margin-top: calc(15vh - 50px);
    background: var(--white);
    border-radius: 10px;
    padding: 45px 60px;
    overflow: auto;

    button {
      margin-bottom: 0px;
      width: 300px;
      padding: 16px 0px;
    }
  }

  .design-box {
    position: relative;
    width: 40%;
    min-width: 450px;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: var(--white);
    border-radius: 10px;
    padding: 45px 60px;
    overflow: auto;
  }

  .table-listings {
    width: 100%;
    // th,
    // td {
    //   padding-left: 30px;
    //   padding-right: 40px;
    // }
  }

  .register-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 0px;
  }

  .register-box-info {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 16px;

    p {
      margin: 0px;
    }
  }

  .register-info,
  .otp-info {
    margin-bottom: 30px;

    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;
      margin-bottom: 12px;

      h3 {
        margin-bottom: 0px;
      }
    }

    p {
      color: var(--muted);
    }
  }

  .close-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .sign-up-text {
    margin-top: 20px;

    span {
      color: var(--primary);
      font-weight: 600;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}

.logged-btn {
  margin-left: 20px;
  margin-top: 0px !important;
  padding: 6px 35px !important;
}

.location {
  grid-column: 1/3;
}

@media screen and (max-width: 960px) {
  .popup-box {
    .login-box {
      height: 100vh;
    }
  }

  .logged-btn {
    margin-left: 0px;
  }

  .login-box {
    width: 100% !important;
    min-height: 100vh !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding: 90px 30px !important;

    .contact-form {
      margin-top: 45px;
    }

    button {
      width: 100% !important;
    }
  }

  .otp-box {
    width: 100% !important;
    min-height: 100vh !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding: 90px 30px !important;

    .inputs {
      gap: 20px !important;

      >div input {
        width: 50px !important;
        height: 50px !important;
      }
    }

  }

  .register-box {
    width: 100% !important;
    min-height: 100vh !important;
    margin-top: 0px !important;
    border-radius: 0px !important;
    padding: 90px 30px !important;

    button {
      width: 100% !important;
    }
  }

}

@media screen and (max-width: 620px) {
  .register-form {
    grid-template-columns: 1fr !important;
  }

  .location {
    grid-column: 1/1 !important;
  }
}