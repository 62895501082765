.back-btn {
  margin: 30px 0px;

  svg {
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    color: var(--black);
  }
}

.details-page-hero {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 90px;

  .details-slides {
    width: 55%;
    border-radius: 10px;
    margin-bottom: 100px;
    border-radius: 10px;
  }

  .slick-list {
    border-radius: 10px;
  }

  .details-slide-img {
    // height: 400px;
    border-radius: 10px;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
    border: 1px solid rgb(235, 235, 235);
    border-radius: 10px;
  }

  .slick-dots .slick-active {
    border: 1px solid rgb(119, 119, 119);

  }

  .slick-next {
    right: 20px;
  }

  .slick-prev::before,
  .slick-next::before {
    color: rgb(187, 187, 187) !important;
  }

  .slick-prev {
    left: 20px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 30px;
  }

  .details-info {
    width: 45%;

    .details-info-head {
      display: flex;
      justify-content: space-between;
      gap: 60px;
      margin-bottom: 10px;
      border-bottom: 2px solid #eee;

      >div p {
        margin-bottom: 0px;
      }

      >div h3 {
        font-weight: 600;
      }

      svg {
        font-size: 20px;
      }
    }

    .details-info-head-icons {
      display: flex;
      gap: 20px;
      align-items: center;

      button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .details-info-options {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-top: 40px;

      span {
        width: 45%;
      }

      span img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .details-infos-price {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 80px;
      border-top: 2px solid #eee;
      padding-top: 20px;
      margin-bottom: 10px;

      >div h5 {
        font-size: 28px;
        margin: 0px;
        margin-bottom: 10px;
      }

      >div h5 span {
        color: var(--black);
        margin-left: 10px;
        font-weight: 600;
      }

      .price-button {
        margin: 0px;
        padding: 10px 30px;
      }
    }
  }

}

.info-list {
  padding: 0px;

  li {
    list-style: none;
    font-weight: 500;
    margin-bottom: 12px;
  }

  >li p {
    display: inline;
    font-weight: 400;

  }
}

.option-icons-hide {
  display: none;
}


.similarP-section-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;

  a {
    text-decoration: none;
    color: var(--black);
    font-weight: 600;
  }

  h3 {
    margin-bottom: 0px;
  }
}

.similar-products {
  margin-bottom: 90px;
}

.similar-products-card {
  padding: 20px;
  padding-left: 4px;
  position: relative;
}

.similar-products-info {
  background-color: var(--white);
  padding: 12px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .type {
    position: absolute;
    top: 40px;
    left: 20px;
    background-color: #E6A859;
    color: var(--white);
    border-radius: 20px;
    padding: 0px 20px;
    font-size: 14px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  p {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500
  }

  h3 {
    font-size: 18px;
    font-weight: 500;
    margin: 0px !important;
  }

  .price {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 0px;
  }
}


//  --------------------
//  --------------------
//  --------------------
// Responsive
//  --------------------
//  --------------------
//  --------------------
@media (min-width:960px) and (max-width:1200px) {
  .details-page-hero .details-info .details-infos-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    border-top: 2px solid #eee;
    padding-top: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width:480px) {

  .option-icons-hide {
    display: block;
  }

  .option-icons-show {
    display: none;
  }

  .option-hide-head {
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 60px !important;
    height: 60px !important;
  }

  .details-infos-price {
    display: block !important;
    padding-top: 0px !important;

    h5 {
      font-size: 24px !important;
    }

    .price-button {
      width: 100%;
      margin-top: 6px !important;
      margin-bottom: 30px !important;
      padding: 10px auto;
    }
  }

  .details-info-options {
    span img {
      width: 15px !important;
      height: 15px !important;
    }
  }
}

@media (max-width:960px) {

  .details-page-hero {
    display: block !important;
    margin-bottom: 60px !important;
  }

  .details-slides {
    width: 100% !important;
  }

  .details-info {
    width: 100% !important;
    margin-top: 60px;
    display: inline-block;
  }

  .details-info-orders {
    display: grid;

    .details-info-head {
      order: 1;
      margin-top: 30px;
      border: none !important;
    }

    .details-infos-price {
      order: 2;
      margin-bottom: 10px;
      border: none !important;

    }

    .details-infos-lastorder {
      order: 3;
    }
  }
}

