.purchased-card {
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  overflow: auto;

  img {
    width: 100%;
    height: 280px;
  }

  h3 {
    font-size: 20px;
    margin: 0px;
  }

  .info {
    padding: 20px;

    h3 {
      cursor: pointer;
    }

    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;

      p {
        color: var(--black);
        font-size: 20px;
        font-weight: 600;
        margin: 0px;
      }

      span {
        font-weight: 500;
        font-size: 14px;

        &::before {
          content: "";
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 6px;
          border-radius: 50%;
          background-color: #4CAF50;
        }
      }
    }
  }
}

// Order Details

.order-details {

  .head,
  .details {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      margin: 0px;
    }
  }

  .details {
    gap: 30px;
    flex-wrap: wrap;
  }

  .info-section {
    border: 1px solid rgb(209, 209, 209);
    margin-bottom: 120px;
    width: 100%;
    padding: 20px;
    border-radius: 12px;

    .info {
      display: flex;
      justify-content: space-between;

      .image-and-details {
        display: flex;
        gap: 30px;
        align-items: center;

        img {
          width: 400px;
          height: fit-content;
        }

        >div h3 {
          max-width: 25ch;
          font-size: 22px;
        }

        >div p {
          font-size: 20px;
          font-weight: 500;
          color: var(--black);
        }
      }
    }
  }

  .buttons .invoice-btn {
    display: block;
    border: 1px solid #999;
    background: none;
    color: var(--black);
    padding: 8px 56px;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 16px;

    svg {
      margin-right: 4px;
    }
  }
}

// Responsive 
@media (max-width: 480px) {
  .order-details .details {
    gap: 10px;
  }
 
}

@media (max-width: 1080px) {
  .order-details .info-section .info {
    display: block;
  }

  .order-details .info-section .info .image-and-details img {
    width: 300px;
  }
}

@media (max-width: 760px) {
  .order-details .info-section .info .image-and-details {
    display: block;
    margin-bottom: 45px;
  }
  .order-details .buttons{
    display: flex;
    gap: 20px;
    button{
      padding: 6px 24px!important;
    }
  }
}