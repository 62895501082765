.pricing-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 90px;
}

.price-card {
  background-image: linear-gradient(140deg, #FAFCE0, #E6ECFB);
  padding: 30px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  height: auto;

  h4 {
    font-weight: 600;
  }
}

.price {
  display: inline-flex;
  margin-top: 10px;
  margin-bottom: 20px;

  p {
    font-weight: 600;
    margin-left: 6px;
    margin-top: 6px;
  }

  >p span {
    text-decoration: line-through;
  }
}

.price-btn {
  width: 100%;
  text-align: center;

  .view-link {
    display: block;
    color: var(--black);
    font-weight: 600;
    margin-top: 20px;
  }
}

.price-option {
  display: flex;
  gap: 20px;

  p {
    color: var(--black);
    font-weight: 500;
  }
}

// Responsive

@media (max-width: 1080px) {
  .pricing-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 760px) {
  .pricing-cards {
    grid-template-columns: repeat(1, 1fr);
  }
}