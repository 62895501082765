header {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 99999;
}

.header {
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 4px 70px;
	position: relative;
	width: 100%;
}

.fixed {
	background-color: var(--white) !important;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.05) !important;
}

.index-header {
	background-color: transparent;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px;
	position: relative;
	box-shadow: none;
}

.nav-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
	max-width: 100%;
}

.nav-logo {
	cursor: pointer;
	flex-grow: 1;
}

.nav-menu {
	display: flex;
	list-style: none;
	text-align: center;
	align-items: center;
	margin-bottom: 0px;

	li {
		padding: 0px 12px;

		a {
			text-decoration: none;
			color: var(--black);
			font-size: 18px;
			font-weight: 400;
		}
	}
}

.nav-menu li a:hover {
	color: var(--primary);
}

.nav-menu li a:active {
	color: var(--primary);
}

.nav-icon,
.nav-icon-bell {
	display: none;
}

.notification-popup {
	background: var(--white);
	width: 360px;
	height: fit-content;
	position: absolute;
	top: 70px;
	right: 130px;
	z-index: 11111111111111;
	border-radius: 10px;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
	padding: 20px;
}

.notification-popup .notification-popup-info {
	display: flex;
	justify-content: space-between;
	// align-items: center;
	gap: 30px;
	margin-bottom: 15px;

	h5 {
		font-weight: 400;
	}

	>div svg {
		font-size: 22px;
		display: block;
		margin-bottom: 4px;
	}

	>div span {
		color: var(--muted);
		cursor: pointer;
	}
}

.notification-popup-content {
	display: flex;
	justify-content: space-between;
	align-items: top;
	gap: 15px;
	border-top: 1px solid #eee;
	text-align: left;
	padding: 15px 0px;

	.popup-image {
		position: relative;

		span {
			display: block;
			width: 7px;
			height: 7px;
			background-color: #DF300A;
			border-radius: 50%;
			position: absolute;
			top: -2px;
		}

		img {
			width: 50px;
			height: 50px;
			border-radius: 10px;
		}
	}


	.popup-content {
		h4 {
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 6px;
		}

		p {
			color: var(--muted);
			font-size: 14px;
			padding-right: 30px;
			margin: 0px;
		}
	}
}

.menu-popup {
	background: var(--white);
	width: 180px;
	height: 260px;
	position: absolute;
	top: 80px;
	right: 50px;
	z-index: 11111111111111;
	border-radius: 10px;
	box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
	padding: 20px;
}

.menu-popup-links {
	a {
		display: block;
		margin-bottom: 15px;
		font-size: 16px !important;
	}

	.log_out {
		text-decoration: none;
		color: var(--black);
		font-weight: 400;
		cursor: pointer;

		&:hover {
			color: var(--primary);
		}
	}
}

.mobile-res-menu,
.profile-accordion {
	display: none;
}

.profile-img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin: 0px 15px 0px 30px;
}

.logged-profile-res {
	display: flex;
	align-items: center;
}


// Responsive

@media screen and (max-width: 1200px) {
	.nav-menu li {
		padding: 0px 6px !important;

		a {
			font-size: 16px !important;
		}
	}

	.profile-img {
		margin: 0px 15px !important;
	}
}

@media screen and (max-width: 990px) {
	.nav-menu li {
		padding: 0px 6px !important;

		a {
			font-size: 15px !important;
		}
	}
}

@media screen and (max-width: 960px) {
	.profile-accordion {
		display: block;
		width: 100%;
		height: fit-content;
		border-bottom: 1px solid #eee;
		padding-bottom: 10px;
		margin-top: 20px;

		.profile-accordion-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 30px;

			h3 {
				margin-bottom: 0px;
			}

			button svg {
				color: var(--black);
			}
		}

	}

	.profile-accordion .faq-btn {
		margin: 0px !important;
	}

	.profile-accordion-links {
		a {
			display: block;
			color: var(--black);
			padding: 10px 0px;
			font-weight: 500;
		}

		.log_out {
			display: block;
			color: var(--black);
			padding: 10px 0px;
			font-weight: 500;
		}
	}

	.logged-profile-res {
		display: none !important;
	}

	.notification-popup {
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		width: 100% !important;
		min-height: 100vh !important;
		margin-top: 0px !important;
		border-radius: 0px !important;
		padding: 20px !important;
		z-index: 9999;
	}

	.notification-popup .notification-popup-info {
		>div {
			display: inline-grid;

			svg {
				font-size: 22px;
				margin-bottom: 10px;
				cursor: pointer;
			}
		}
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		width: 100%;
		position: absolute;
		top: 80px;
		left: -110%;
		opacity: 1;
		transition: all 0.5s ease;
		padding: 0px 60px;

		li {
			padding: 16px 0px !important;
			width: 100%;
			display: table;

			a {
				font-size: 22px !important;
			}
		}

		button {
			margin-left: 0px !important;
			margin-top: 20px !important;
			margin-bottom: 50px;
			padding: 10px !important;
		}
	}

	.nav-menu.active {

		background: var(--white);
		margin-top: -80px;
		left: 0px;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1111;
		padding: 30px 30px;
		text-align: left;
		height: 100vh;
		overflow: scroll;

		li {
			border-bottom: 1px solid #eee;
			padding: 15px 0px;

			a {
				font-weight: 500;
				font-size: 20px;
			}
		}

		.logged-btn {
			position: fixed;
			width: 90%;
			bottom: 40px;
			padding: 10px 0px !important;
			display: block !important;
			margin-left: 30px !important;
			margin-right: 30px !important;
		}

		.mobile-res-menu {
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			gap: 30px;
			margin-bottom: 15px;

			h3 {
				margin-bottom: 0px;
			}

			svg {
				font-size: 24px;
				cursor: pointer;
			}
		}
	}

	.nav-icon {
		display: block;
		font-size: 28px;
		cursor: pointer;
		color: var(--black);
	}

	.nav-icon-bell {
		display: block;
	}
}

@media (max-width:768px) {
	.header {
		padding: 0px 10px;
	}
}