.select-card,
.select-card-active {
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 10px;
  padding-bottom: 0px;
  border-radius: 10px;
  background: transparent;
  border: none;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  background-color: var(--white);
}

.select-card {
  .select-type-active {
    display: none;
  }
}

.select-card-active {
  .select-type-active {
    position: absolute;
    display: block;
    top: 16px;
    right: 24px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--black);
    transition: 2s;

    span {
      display: block;
      color: var(--white);
      margin-top: 1px;
    }
  }
}

.cards-four-list,
.cards-three-list {
  display: grid;
  gap: 26px;
  margin-bottom: 200px;
}

.cards-four-list {
  grid-template-columns: repeat(4, 1fr);
}

.cards-three-list {
  grid-template-columns: repeat(3, 1fr);

  .select-image {
    height: 350px !important;
  }
}

.colour-images {
  .select-image {
    padding: 40px 30px 20px;
    height: 230px !important;
  }
}

.select-options {
  margin-bottom: 90px;
}

.select-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;

  h3 {
    margin-bottom: 0px;
  }

  span {
    font-size: 22px;
    font-weight: 600;
  }
}

.select-image {
  width: 100%;
  max-width: 100%;
  height: 12vw;
  border-radius: 10px;
}

.select-title {
  font-size: 24px;
  margin: 16px 0px;
  font-weight: 500;
}

.select-type {
  position: absolute;
  top: 16px;
  left: 24px;

  .free,
  .premium {
    color: var(--white);
    padding: 2px 14px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
  }

  .premium {
    background-color: #E6A859;
  }

  .free {
    background-color: var(--primary);
  }
}

.breadcrumb {
  position: fixed;
  background-color: var(--white);
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px 100px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  z-index: 999;

  >div {
    font-weight: 600;
  }

  >div p {
    display: inline;
    margin: 0;
    color: var(--black);
    font-weight: 400;
  }

  button {
    margin: 0;
    padding: 12px 64px;
  }
}

// furniture slides
.furniture-slide {
  margin-bottom: 30px;

  button:focus-visible {
    outline: none !important;
  }

  .slick-slide {
    margin-right: 20px !important;
    margin-bottom: 20px;
  }

  .slick-track {
    height: 400px;
  }

  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 60px;
    top: 40%;
  }

  .slick-prev {
    left: 20px;
    z-index: 111;
    top: 40%;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 50px;
    color: var(--black);
  }
}

// Responsive
@media (max-width:1120px) {
  .cards-four-list {
    grid-template-columns: repeat(3, 1fr);

    .select-image {
      height: 15vw !important;
    }
  }

  .cards-three-list .select-image {
    height: 280px !important;
  }

}

@media (max-width:786px) {
  .cards-four-list {
    grid-template-columns: repeat(2, 1fr);

    .select-image {
      height: 27vw !important;
    }
  }

  .cards-three-list {
    grid-template-columns: repeat(2, 1fr);

    .select-image {
      height: 250px !important;
    }
  }

  .select-head {

    h3,
    span {
      font-size: 22px;
      max-width: 18ch;
    }
  }

  .breadcrumb {
    padding: 20px 20px;
  }

  .select-title {
    font-size: 20px;
    margin: 16px 0px;
    font-weight: 500;
  }
  .select-type {
    position: absolute;
    top: 12px;
    left: 16px;
    .free, .premium {
      color: var(--white);
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: 500;
    }
  }
}

@media (max-width:470px) {

  .cards-four-list,
  .cards-three-list {
    gap: 10px;

  }

  .cards-four-list .select-image,
  .cards-three-list .select-image {
    height: 27vw !important;
  }

  .select-title {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  .breadcrumb {
    justify-content: center;
    text-align: left;
    padding: 12px;

    // >div {
    //   display: none;
    // }

    .button {
      padding: 6px 24px;
      font-size: 14px;
      margin-right: 6px;
    }
  }

  .select-type {
    position: absolute;
    top: 7px;
    left: 16px;
    .free, .premium {
      color: var(--white);
      padding: 2px 5px;
      border-radius: 10px;
      font-size: 7px;
      font-weight: 500;
    }
  }
  
}