.payments-title {
  margin-top: 30px;
  display: inline-block;
}

.payments-table {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 120px;

  tr {
    border-bottom: 1px solid rgba(12, 11, 11, 0.2);
  }

  th,
  td {
    padding: 15px;
  }

  tr>.invoice-btn span {
    border: 1px solid rgba(12, 11, 11, 0.2);
    padding: 8px 16px;
    border-radius: 12px;
    cursor: pointer;

    svg {
      margin-right: 6px;
    }
  }
}

// Invoice

.invoice-box {
  position: fixed;
  background: #fff;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999999999999;
}

.invoice-info-box {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.invoice-head {
  background-color: #F1F3F4;
  padding: 90px;

  .logo-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 80px;
      height: 80px;
    }

    >div {
      span {
        border: 1px solid #999;
        padding: 8px 12px;
        border-radius: 12px;
        margin-right: 20px;
      }

      svg {
        font-size: 20px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }

  .invoice-data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 120px;
    row-gap: 26px;
    margin-top: 60px;

    >div {
      display: flex;
      align-items: center;
      gap: 30px;

      h5 {
        width: 280px;
        font-size: 24px;
        margin: 0px;
      }

      p {
        font-size: 20px;
        margin: 0px;
      }
    }
  }
}

.bill-info {
  margin: 60px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;

  h3 {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 20px
  }

  p {
    font-size: 18px;
  }

  .item-data {
    margin-top: 45px;

    >div {
      margin-bottom: 12px;

      span {
        margin-right: 8px;
        font-weight: 600;
        font-size: 20px;
        font-weight: 500;
      }

      p {
        display: inline;
      }
    }
  }
}

.invoice-table {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 120px;
  border-spacing: 15px;
  border-collapse: collapse;

  tr {
    border: 1px solid #0C0B0B;
    border-left: 0px;
    border-right: 0px;
  }

  .invoice-info {
    border: none !important;
  }

  th,
  td {
    padding: 15px;
  }
}

.invoice-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.invoice-sign {
  img {
    width: 200px;
    margin-top: 100px;
  }
}

.invoice-qr img {
  width: 300px;
  height: 300px;
}

.invoice-copyright {
  max-width: 90%;
  margin-top: 60px;
  margin-bottom: 90px;
}

// Responsive

@media (max-width: 1300px) {

  .invoice-head .invoice-data {
    gap: 30px !important;
  }

  .invoice-head .invoice-data>div h5 {
    width: 180px !important;
    font-size: 20px !important;
  }

  .invoice-head .invoice-data>div p {
    font-size: 18px !important;
  }
}


@media (max-width: 1200px) {

  .payments-table,
  .invoice-table {
    width: 1000px;
  }
}

@media screen and (max-width: 960px) {
  .invoice-head {
    padding: 30px;
  }

  .invoice-head .invoice-data {
    grid-template-columns: 1fr !important;
  }

  .bill-info {
    grid-template-columns: 1fr !important;
    gap: 60px !important;
  }
}

@media screen and (max-width: 480px) {
  .invoice-head .invoice-data>div {
    flex-wrap: wrap;
  }

  .invoice-head .invoice-data>div h5 {
    width: 100px !important;
    font-size: 18px !important;
  }

  .invoice-head .invoice-data>div p {
    font-size: 16px !important;
  }

  .invoice-sign img {
    margin-top: 30px;
  }

  .invoice-qr img {
    width: 240px;
    height: 240px;
  }
}