/* General styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;900&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --black: #0C0B0B;
  --white: #ffffff;
  --muted: #999999;
  --primary: #0A82DF;
  --success: #28a745;
  --secondary: #f8f8f8;
}

a {
  text-decoration: none;
  color: var(--primary);
}

h2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.3;
}

h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 18px;
}

p {
  color: var(--muted);
}

.button {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 16px 48px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;

  a {
    color: var(--white);
    text-decoration: none;
  }
}

.button-saved {
  border: none;
  background: var(--success);
  color: var(--white);
  padding: 16px 48px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;

  a {
    color: var(--white);
    text-decoration: none;
  }
}

// Index or Homepage
@import url(./components-styles/index.scss);

// Index or Selectoptions
@import url(./components-styles/select-options.scss);

// Faq
@import url(./components-styles/faq.scss);

// Help
@import url(./components-styles/help.scss);

// Our designgers and designer profile
@import url(./components-styles/our-designers.scss);

// Pricing
@import url(./components-styles/pricing.scss);

// Your Design
@import url(./components-styles/your-design.scss);

// Product datails
@import url(./components-styles/product-details-page.scss);

// About and Contact
@import url(./components-styles/about-and-contact.scss);

// Header
@import url(./components-styles/header.scss);

// Footer
@import url(./components-styles/footer.scss);

// How it works page
@import url(./components-styles/how-it-works.scss);

// Login and Signup
@import url(./components-styles/login-and-signup.scss);

// Profile
@import url(./components-styles/profile.scss);

// Payments
@import url(./components-styles/payments.scss);

// My Designs, My Favourites
@import url(./components-styles/my-designs.scss);

// Purchased items
@import url(./components-styles/purchased-items.scss);


// Comman Code
.page-head {
  text-align: center;
  margin: 70px 0px;

  h2 {
    font-weight: 600;
    margin: 12px 0px;
  }

  p {
    color: var(--muted);
    font-size: 20px;
  }

  .page-search {
    width: 330px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 4px;
    margin-top: 30px;
    border: 2px solid #e2e2e2;

    span {
      background: transparent;
      border: none;
      color: var(--muted);
      padding-right: 0px;
    }

    input {
      background: transparent;
      color: var(--muted);
      border: none;

      &:focus {
        border: none !important;
        box-shadow: none !important;
        background: transparent;

      }
    }
  }
}

.select-back-arrow {
  margin-right: 10px;
  cursor: pointer;
  color: var(--black);
}

// Loading page
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 180px;
  margin-bottom: 100px;
}


.loading {
  width: fit-content;
  position: relative;
}

.loading-spin {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.load-text {
  font-size: 22px;
  text-align: center;
  position: absolute;
  left: 90px;
  top: 170px;
  font-weight: 700;
}

.premium {
  color: #000;
  font-size: 24px;
  text-align: center;
}

.premium_data .modal-footer {
  justify-content: center;
}

.purchase {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.purchase_btn {
  border: none;
  background: var(--primary);
  color: var(--white);
  padding: 10px 40px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
}

.purchase_btn:hover {
  color: var(--white);
}

.notification_clearall {
  display: flex !important;
}

.notification-card .card-body {
  padding: 10px 10px 10px 10px;
  
}

.card-body table {
  width: 100%;
}

.card-body table tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body table tr td button.btn {
  font-size: 0.8rem;
  padding: 3px;
  margin: 6px 6px 6px 6px;
}

.card-body table tr td:nth-child(2) {
  text-align: right;
  justify-content: space-around;
}

.card-title:before {
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.1rem;
  text-align: center;
  border: 2px solid grey;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  padding-bottom: 3px;
  margin-right: 10px;
}

.card-notification-main {
  display: grid;
}

.card-notification-main .card-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.card-notification-main .card-desc {
  color: #000;
  font-size: 14px;
}

.card.display-none {
  display: none;
  transition: opacity 2s;
}

.dismiss {
  text-align: end;
}

.dismiss-all {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:hover {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:active {
  background: transparent;
  color: #0A82DF;
  border: 0;
}

.dismiss-all:focus {
  background: transparent;
  color: #0A82DF;
  border: 0;
  box-shadow: none !important;
}

.notification-card {
  margin: 20px 0px 20px 0px;
}

.notification-view {
  align-items: top;
  gap: 15px;
  border-top: 1px solid #eee;
  text-align: left;
  padding: 15px 0px;
}

.view-notifi {
  color: #000;
  font-size: 16px;
  text-align: center;
}

.notification_read {
  font-size: 14px;
  color: #64686a;
  margin-bottom: 0;
  font-weight: 700;
}

.notification_unread {
  font-size: 14px;
  color: #000;
  margin-bottom: 0;
  font-weight: 700;
}

.mobile-num-radius {
  border-radius: 10px 0px 0px 10px !important;
  width: 73px;
}

/*======================
    404 page
=======================*/


.page_404 {
  padding: 0px 0px 0px 0px;
  background: #fff;
  font-family: 'Arvo', serif;
}

.contant_box_404 .whoops {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .something_went {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .h2 {
  color: #02675f;
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 .cant_find {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
}

// .four_zero_four_bg {
//   height: 300px;
//   background-position: center;
//   // margin-top: 90px;
//   position: relative;
// }

.logo_img img {
  height: 70px;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  font-size: 14px;
  padding: 10px 32px;
  background: #0888dd;
  /* margin: 20px 0; */
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;
}

.contant_box_404 {
  position: absolute;
  left: 8%;
  /* bottom: 30px; */
  top: 40%;
}

// Responsive
@media (max-width:960px) {
  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 24px;
  }
}



.slide-icons2 {
  height: calc(100% - 54px);
}



