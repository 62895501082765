.howitworks-page {
  margin-top: 160px;
  margin-bottom: 120px;

  .howitworks-head {
    text-align: center;
    margin-bottom: 90px;

    .info {
      font-size: 24px;
    }

    .description {
      padding: 0px 60px;
    }
  }
}

.know-how-title,
.what-wedo-title {
  margin-bottom: 60px;
  text-align: center;
  font-size: 42px;
}

// Know How
.know-how-section {
  position: relative;
  margin-bottom: 320px;

  .hit-v1 {
    width: 100%;
    height: 550px;
  }

  .hit-v2 {
    display: none;
  }
}

.hit-card {
  width: 300px;
  height: 300px;

  img {
    width: 140px;
    height: 140px;
  }
}

.hit-p1-img {
  position: absolute;
  top: 0;
  left: 50px;
}

.hit-p2-img {
  position: absolute;
  top: 0;
  left: 43%;
}

.hit-p3-img {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.hit-p4-img {
  position: absolute;
  bottom: -40%;
  right: 10%;
}

.hit-p5-img {
  position: absolute;
  bottom: -40%;
  left: 20%;
}

.hit-info {
  text-align: left;
  background-color: var(--white);
  margin-top: 20px;

  span {
    font-size: 64px;
    font-weight: 600;
    color: #F5F5F5;
  }

  p {
    margin-top: -60px;
    margin-left: 50px;
    color: var(--black);
    max-width: 22ch;
    text-align: center;
    font-size: 16px;
  }
}

// What We Do
.what-wedos {
  position: relative;
}

.what-wedo-bg {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 50%;
    // width: 70%;
    height: auto;
  }
}

.what-wedo-items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.what-wedo-item {
  display: flex;
  width: 100%;
  gap: 120px;
  margin-bottom: 120px;

}

.ww-item-img {
  width: 50%;

  img {
    width: 416px;
    // width: 380px;
    height: unset;
    border-radius: 20px;
    border: 1px solid rgb(202, 202, 202);
  }
}

.ww-item-info {
  width: 50%;

  h4 {
    margin-bottom: 12px;
  }

  p {
    max-width: 45ch;
    margin-bottom: 0px;
  }

  span {
    display: block;
    color: var(--muted);
    font-size: 16px;
    margin-bottom: 12px;

  }

  span::before {
    content: '';
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 7.5px;
    background-color: var(--primary);
    margin-right: 8px;
    margin-top: -2px;
  }
}

.ww-last-item-flex {
  display: flex;
  gap: 10px;
}

// Responsive

@media (max-width: 1080px) {
  .what-wedo-bg {
    display: none !important;
  }

  .what-wedos {
    position: unset !important;
  }

  .what-wedo-items {
    position: unset !important;
  }
}

@media (min-width: 1280px) and (max-width: 1400px) {
  .what-wedo-bg {
    img {
      width: 80% !important;
    }
  }
}

@media (min-width: 1180px) and (max-width: 1279px) {
  .what-wedo-bg {
    img {
      width: 90% !important;
    }
  }
}

@media (min-width: 1080px) and (max-width: 1179px) {
  .what-wedo-bg {
    img {
      width: 100% !important;
    }
  }
}


@media (max-width: 1180px) {
  .know-how-section {
    margin-bottom: 320px !important;

    .hit-v1 {
      height: 450px !important;
    }
  }

  .hit-card {
    width: 200px !important;
    height: 200px !important;

    img {
      width: 100px !important;
      height: 100px !important;
    }
  }

  //--//

}

@media (max-width: 980px) {
  .ww-last-item-flex {
    flex-direction: column;
  }
}

@media (max-width: 960px) {
  .know-how-section {
    margin-bottom: 320px !important;

    .hit-v1 {
      height: 450px !important;
    }
  }

  .hit-info {

    span {
      font-size: 42px !important;
    }

    p {
      max-width: 25ch;
      font-size: 14px !important;
    }
  }

  .hit-p3-img {
    top: 30% !important;
    right: 0;
    text-align: right;
  }

  //--//
  .what-wedo-item {
    display: block;
    margin-bottom: 60px;
  }

  .ww-item-img {
    width: 100%;
    margin: 30px 0px;

    img {
      width: 100%;
    }
  }

  .ww-item-info {
    width: 100%;
    margin: 30px 0px;
  }

  .ww-last-item-flex {
    display: block;
  }
}

@media (max-width: 600px) {
  .howitworks-page {
    margin-top: 120px !important;
  }

  .howitworks-head {
    margin-bottom: 90px !important;

    .info {
      font-size: 22px !important;
    }

    .description {
      padding: 0px !important;
    }
  }

  .know-how-title,
  .what-wedo-title {
    font-size: 32px !important;
  }

  .know-how-section {
    margin-bottom: 220px !important;

    .hit-v1 {
      display: none !important;
    }

    .hit-v2 {
      display: block !important;
      width: 100% !important;
      height: auto !important;
    }
  }

  .hit-info {
    background-color: transparent !important;
  }

  .hit-p1-img {
    top: -3% !important;
    left: 50px !important;
  }

  .hit-p2-img {
    top: 20% !important;
    right: 0 !important;
    text-align: center !important;

  }

  .hit-p3-img {
    top: 43% !important;
    left: 0 !important;
    text-align: center !important;
  }

  .hit-p4-img {
    bottom: 15% !important;
    right: 0 !important;
    text-align: center !important;
  }

  .hit-p5-img {
    bottom: -10% !important;
    left: 0 !important;
    text-align: center !important;
  }

  //--//

}

@media (max-width: 400px) {
  .howitworks-head {
    margin-bottom: 60px;

    .info {
      font-size: 16px !important;
    }

    .description {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 360px) {
  .hit-info {
    margin-top: 6px !important;

  }

  .hit-info p {
    font-size: 12px !important;
  }

  .hit-p1-img {
    top: -3% !important;
    left: 50px !important;
  }

  .hit-p2-img {
    top: 20% !important;
    text-align: center !important;
    left: 30%;
  }

  .hit-p3-img {
    top: 43% !important;
    left: 0 !important;
    text-align: center !important;
  }

  .hit-p4-img {
    bottom: 7% !important;
  }

  .hit-p5-img {
    bottom: -15% !important;
  }
}