.designer-title {
  margin: 30px 0px;
  display: inline-block;
}

.designers {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 90px;
}

.designer-card {
  width: 48%;
  height: auto;
  background-color: var(--white);
  padding: 16px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;

  .slick-slider,
  .slick-slide img {
    height: unset;
    border-radius: 10px;
    height: 350px;
    object-fit: cover;
  }
}

.slide-icons {
  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 14px;
  }

  .slick-prev {
    left: 0px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 35px;
    color: var(--black);
  }
}

.slide-icons2 {
  .slick-dots {
    bottom: -100px !important;
    display: flex !important;
    justify-content: space-between;
  }

  .slick-dots li {
    width: 80px;
    height: 80px;
    margin: 0px !important;
  }

  .slick-next {
    right: 22px;
  }

  .slick-prev {
    left: 6px;
    z-index: 111;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 35px;
  }
}

.designer-card-info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  img {
    width: 54px;
    height: 54px;
    border-radius: 50%;
  }

  >div h4 {
    margin-bottom: 4px;
    color: var(--black);
  }

  >div span {
    font-size: 14px;
    color: var(--black);
  }
}


// Desiner Profile
.portfolio-slider {
  margin-bottom: 90px;
}

.portfolio-card {
  padding: 20px;
  padding-left: 4px;
}

.portfolio-info {
  background-color: var(--white);
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-radius: 10px;
  }

  h3 {
    font-size: 18px;
    margin: 20px 0px;
    font-weight: 500;
  }
}

.designer-profile {
  padding: 30px;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.15);
  margin-bottom: 60px;


  h4 {
    font-weight: 600;
    margin-bottom: 24px;
  }

  .profile-wrap {
    display: flex;
    gap: 60px;
  }

  .profile-info {

    img {
      width: 350px;
      height: unset;
      border-radius: 10px;
    }
  }

  .profile-tabs {
    .nav-tabs {
      border: none;
    }

    .nav-tabs .nav-link {
      border: none;
      color: var(--black);
      padding: 0 !important;
      margin-right: 30px;
      margin-bottom: 30px;
      font-size: 24px;
    }

    .nav-link:focus,
    .nav-link:hover,
    .nav-link:active {
      font-weight: 600;
      position: relative;
    }

    .nav-link:focus::after {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      left: 0;
      bottom: -6px;
      border-bottom: 3px solid;
      border-color: var(--primary);
      border-radius: 20px;
    }
  }
}

// Responsive
@media (max-width: 1200px) {
  .designer-card {
    width: 45%;
  }

  .designers {
    gap: 30px;
    justify-content: center;
  }

  .designer-profile .profile-tabs .nav-tabs .nav-link {
    margin-right: 20px !important;
    font-size: 20px !important;
  }
}

@media (max-width: 1080px) {
  .designer-profile .profile-tabs .nav-tabs .nav-link {
    margin-right: 12px !important;
    font-size: 16px !important;
  }

  .tab-content p {
    font-size: 16px !important;
  }
}

@media (max-width: 960px) {
  .portfolio-info h3 {
    font-size: 14px !important;
  }

  .designer-profile .profile-tabs .nav-tabs .nav-link {
    margin-right: 20px !important;
  }

  .designer-title {
    margin-top: 0px !important;
  }

  .designer-card {
    width: 100%;
  }

  .designer-profile {
    .profile-wrap {
      display: block;
    }
  }

  .profile-info img {
    margin-bottom: 30px;
  }

  .profile-tabs .nav-tabs .nav-link {
    font-size: 20px !important;
  }
}

@media (max-width: 490px) {
  .profile-info img {
    width: 100% !important;
  }

  .profile-tabs .nav-tabs .nav-link {
    font-size: 16px !important;
    margin-right: 0px !important;
    margin-bottom: 14px !important;
  }

  .designer-profile .profile-tabs .nav-tabs {
    display: block;
    margin-bottom: 20px;
  }

  .tab-content p {
    font-size: 14px !important;
  }
}

@media (max-width: 520px) {
  .designers {
    gap: 20px;
  }

  .designer-card {

    .slick-slider,
    .slick-slide img {
      height: 220px;
    }
  }
}